import React, { useState, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import useAuthStore from '../../../store/useAuthStore';
import useReportStore from '../../../store/useReportStore';
import { isValidatorUser } from '../../../utils/report';
import Icon from '../../../assets/icons/SvgComponent';
import CustomModal from '../../CustomModal';
import ConfirmationModal from './confirmationModal';

interface ContentProps {
  reportForm: ReportFormType;
  setReportForm?: React.Dispatch<React.SetStateAction<ReportFormType>>;
  clientReport?: ReportFormType;
  handleSubmit?: () => void;
  lastMonth?: boolean;
  loading?: any;
}
interface ReportFormType {
  report?: {
    outreach_url?: string;
    links?: any[];
    citations?: any[];
    [key: string]: any;
  };
  optional_fields?: string[];
  report_type?: string[];
}

const initialReport = [
  'overview',
  'traffic_and_conversion',
  'keywords_ranking',
  'opportunities',
  'work_completed',
  'work_planned',
  'performance',
  'links',
];

const Content: React.FC<ContentProps> = ({
  reportForm,
  setReportForm,
  clientReport,
  handleSubmit,
  lastMonth,
  loading,
}) => {
  const { reportStatus } = useReportStore((state) => state.filters);
  const outreach_url = reportForm?.report?.outreach_url;
  const { user } = useAuthStore((state) => state);
  const [activeItems, setActiveItems] = useState<string[]>(initialReport);
  const isChanged = JSON.stringify(clientReport) !== JSON.stringify(reportForm);
  const [disableSave, setDisableSave] = useState(false);
  const [openDeleteLinkModal, setOpenDeleteLinkModal] = useState(false);
  const [openDeleteCitationModal, setOpenDeleteCitationModal] = useState(false);

  // SAVING NEW REPORT lINK ONLY IN STATE
  const [addReportLink, setAddReportLink] = useState(false);
  const [linkIndex, setLinkIndex] = useState(0);
  const [newLink, setNewLink] = useState({
    anchor: '',
    domain_ranking: '',
    target: '',
    link: '',
  });

  const handleNewLinkInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewLink((prevLink) => ({
      ...prevLink,
      [name]: value,
    }));
  };

  const handleNewLinkSubmit = () => {
    const updatedLinks = [...links, newLink];
    if (setReportForm) {
      setReportForm((prevForm) => ({
        ...prevForm,
        report: {
          ...prevForm.report,
          links: updatedLinks,
        },
      }));
    }
    setNewLink({ anchor: '', domain_ranking: '', target: '', link: '' });
    setAddReportLink(false);
  };

  const handleDeleteLink = (indexToDelete: number) => {
    const updatedLinks = links.filter((_, index) => index !== indexToDelete);

    if (setReportForm) {
      setReportForm((prevForm) => ({
        ...prevForm,
        report: {
          ...prevForm.report,
          links: updatedLinks,
        },
      }));
      setOpenDeleteLinkModal(false);
    }
  };

  // END SAVING NEW REPORT lINK ONLY IN STATE

  const [addReportCitations, setAddReportCitations] = useState(false);
  const [citationIndex, setCitationIndex] = useState(0);
  const [hideIcons, setHideIcons] = useState<string[]>(
    reportForm?.optional_fields || [] //
  );
  // ['opportunities', 'work_planned', 'links']
  const [newCitation, setNewCitation] = useState('');

  const handleNewCitationInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setNewCitation(value);
  };

  const handleNewCitationSubmit = () => {
    const updatedCitations = [...citations, newCitation];
    if (setReportForm) {
      setReportForm((prevForm) => ({
        ...prevForm,
        report: {
          ...prevForm.report,
          citations: updatedCitations,
        },
      }));
    }
    setNewCitation('');
    setAddReportCitations(false);
  };

  const handleDeleteCitation = (indexToDelete: number) => {
    const updatedCitations = citations.filter(
      (_, index) => index !== indexToDelete
    );

    if (setReportForm) {
      setReportForm((prevForm) => ({
        ...prevForm,
        report: {
          ...prevForm.report,
          citations: updatedCitations,
        },
      }));
      setOpenDeleteCitationModal(false);
    }
  };

  const handleCheckboxChange = (item: string) => {
    const tempOptions = reportForm?.optional_fields?.includes(item)
      ? reportForm?.optional_fields?.filter((i) => i !== item)
      : [...(reportForm?.optional_fields ?? []), item];
    if (setReportForm) {
      setReportForm((prevForm) => ({
        ...prevForm,
        optional_fields: tempOptions,
      }));
      setHideIcons(tempOptions);
    }
    const tempItems = [...activeItems];
    const index = tempItems.indexOf(item);
    if (index > -1) {
      tempItems.splice(index, 1);
    } else {
      tempItems.push(item);
    }
    setActiveItems(initialReport);
  };

  const disableEditing =
    !isValidatorUser(user) &&
    !['in_progress', 'rejected', 'draft'].includes(reportStatus.id);
  const links = reportForm?.report?.links || [];
  const citations = reportForm?.report?.citations || [];
  const isSeo = reportForm?.report_type?.includes('seo');

  const [activeLinks, setActiveLinks] = useState<boolean>(
    links.length > 0 || citations.length > 0
  );

  const handleSetActiveItems = (item: string) => {
    const tempItems = [...activeItems];
    const index = tempItems.indexOf(item);
    if (index > -1) {
      tempItems.splice(index, 1);
    } else {
      tempItems.push(item);
    }
    setActiveItems(tempItems);
  };

  const handleInputChnage = useCallback(
    (text: string, key: string) => {
      if (reportForm.report?.[key] !== text && setReportForm) {
        setReportForm({
          ...reportForm,
          report: {
            ...reportForm.report,
            [key]: text,
          },
        });
      }
    },
    [reportForm, setReportForm]
  );

  // const elements =
  //   reportForm?.report &&
  //   Object.entries(reportForm?.report)
  //     .slice()
  //     ?.filter(
  //       ([key]) =>
  //         ![
  //           'links',
  //           'strategy',
  //           'strategy_url',
  //           'dashthis_url',
  //           'dashthis',
  //           'outreach_url',
  //           'rank_ranger_url',
  //           'reporting_method',
  //           'citations',
  //         ].includes(key)
  //     )
  //     ?.map(([key, value]) => {
  //       const title = key
  //         ?.split('_')
  //         .map((word) => word.charAt(0)?.toUpperCase() + word?.slice(1))
  //         .join(' ');
  //       // function createMarkup() {
  //       //   return { __html: value };
  //       // }
  //       const modules = {
  //         toolbar: [[{ list: 'ordered' }, { list: 'bullet' }]],
  //       };
  //       const formats = ['list', 'bullet', 'indent'];
  //       return (
  //         <div
  //           key={key}
  //           onClick={(e) => e.stopPropagation()}
  //           className="flex flex-col items-start p-2"
  //         >
  //           <div className="flex items-center justify-between w-full h-10 cursor-pointer ">
  //             <div
  //               className="flex items-center gap-2 "
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //               }}
  //             >
  //                {' '}
  //               <span className="text-[#001C44] text-sm font-semibold dark:text-white">
  //                 {title}
  //               </span>
  //
  //               {key === 'work_planned' ? (
  //                 <div className="flex gap-2 items-center">
  //                   <input
  //                     style={{ cursor: 'pointer' }}
  //                     type="checkbox"
  //                     onChange={() => {
  //                       handleCheckboxChange(key);
  //                     }}
  //                     checked={reportForm?.optional_fields?.includes(key)}
  //                   />
  //                   <label className="dark:text-white text-xs">
  //                     Not required
  //                   </label>
  //                 </div>
  //               ) : null}
  //               {key === 'opportunities' ? (
  //                 <div className="flex gap-2 items-center">
  //                   <input
  //                     style={{ cursor: 'pointer' }}
  //                     type="checkbox"
  //                     onChange={() => handleCheckboxChange(key)}
  //                     checked={hideIcons?.includes(key)}
  //                   />
  //                   <label className="dark:text-white text-xs">
  //                     Not required
  //                   </label>
  //                 </div>
  //               ) : null}
  //             </div>
  //             {hideIcons?.includes(key) ? null : (
  //               <>
  //                 {activeItems?.includes(key) ? (
  //                   <button onClick={() => handleSetActiveItems(key)}>
  //                     <AiOutlineMinus />
  //                   </button>
  //                 ) : (
  //                   <button onClick={() => handleSetActiveItems(key)}>
  //                     <AiOutlinePlus />
  //                   </button>
  //                 )}
  //               </>
  //             )}
  //           </div>
  //           {loading ? (
  //             <div className="flex flex-col w-full h-[300px] items-center justify-center border rounded lg dark:border-white">
  //               <Icon name="Loading" />
  //               <p className="dark:text-white">Loading, please wait...</p>
  //             </div>
  //           ) : (
  //             <>
  //               {!hideIcons?.includes(key) && (
  //                 <>
  //                   {activeItems?.includes(key) || lastMonth ? (
  //                     <div className={`text-editor `}>
  //                       <ReactQuill
  //                         theme="snow"
  //                         value={value}
  //                         onChange={(e) =>
  //                           !lastMonth && handleInputChnage(e, key)
  //                         }
  //                         modules={modules}
  //                         formats={formats}
  //                         readOnly={lastMonth || disableEditing}
  //                       />
  //                     </div>
  //                   ) : null}
  //                 </>
  //               )}
  //             </>
  //           )}
  //         </div>
  //       );
  //     });

  if (!reportForm?.report)
    return (
      <span style={{ marginLeft: 32, color: '#000', fontSize: 16 }}>
        No previous month report
      </span>
    );
  let reportData = [
    {
      title: 'Overview',
      key: 'overview',
      content: reportForm?.report?.overview,
    },
    {
      title: 'Traffic and Conversion',
      key: 'traffic_and_conversion',
      content: reportForm?.report?.traffic_and_conversion,
    },
    {
      title: 'Keywords Ranking',
      key: 'keywords_ranking',
      content: reportForm?.report?.keywords_ranking,
    },
    {
      title: 'Opportunities',
      key: 'opportunities',
      content: reportForm?.report?.opportunities,
    },
    {
      title: 'Work Completed',
      key: 'work_completed',
      content: reportForm?.report?.work_completed,
    },
    {
      title: 'Work Planned',
      key: 'work_planned',
      content: reportForm?.report?.work_planned,
    },
  ];
  if (!isSeo) {
    reportData = reportData
      .concat([
        {
          title: 'Performance',
          key: 'performance',
          content: reportForm?.report?.performance,
        },
      ])
      .filter(
        (item) =>
          item.key !== 'traffic_and_conversion' &&
          item.key !== 'keywords_ranking'
      );
  }
  const content = reportData?.map((item, index) => {
    const modules = {
      toolbar: [[{ list: 'ordered' }, { list: 'bullet' }]],
    };
    const formats = ['list', 'bullet', 'indent'];
    return (
      <div
        key={index}
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col items-start p-2"
      >
        <div className="flex items-center justify-between w-full h-10 cursor-pointer ">
          <div
            className="flex items-center gap-2 "
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
             {' '}
            <span className="text-[#001C44] text-sm font-semibold dark:text-white">
              {item.title}
            </span>
             
            {item.key === 'work_planned' ? (
              <div className="flex gap-2 items-center">
                <input
                  style={{ cursor: 'pointer' }}
                  type="checkbox"
                  onChange={() => {
                    handleCheckboxChange(item.key);
                  }}
                  checked={reportForm?.optional_fields?.includes(item.key)}
                />
                <label className="dark:text-white text-xs">Not required</label>
              </div>
            ) : null}
            {item.key === 'opportunities' ? (
              <div className="flex gap-2 items-center">
                <input
                  style={{ cursor: 'pointer' }}
                  type="checkbox"
                  onChange={() => handleCheckboxChange(item.key)}
                  // checked={hideIcons?.includes(item.key)}
                />
                <label className="dark:text-white text-xs">Not required</label>
              </div>
            ) : null}
          </div>
          {hideIcons?.includes(item.key) ? null : (
            <>
              {activeItems?.includes(item.key) ? (
                <button onClick={() => handleSetActiveItems(item.key)}>
                  <AiOutlineMinus />
                </button>
              ) : (
                <button onClick={() => handleSetActiveItems(item.key)}>
                  <AiOutlinePlus />
                </button>
              )}
            </>
          )}
        </div>
        {loading ? (
          <div className="flex flex-col w-full h-[300px] items-center justify-center border rounded lg dark:border-white">
            <Icon name="Loading" />
            <p className="dark:text-white">Loading, please wait...</p>
          </div>
        ) : (
          <>
            {!hideIcons?.includes(item.key) && (
              <>
                {activeItems?.includes(item.key) || lastMonth ? (
                  <div className={`text-editor `}>
                    <ReactQuill
                      theme="snow"
                      value={item.content}
                      onChange={(e) =>
                        !lastMonth && handleInputChnage(e, item.key)
                      }
                      modules={modules}
                      formats={formats}
                      readOnly={lastMonth || disableEditing}
                    />
                  </div>
                ) : null}
              </>
            )}
          </>
        )}
      </div>
    );
  });

  const handleSaveBtn = () => {
    setDisableSave(true);
    if (handleSubmit) {
      handleSubmit();
    }
    setDisableSave(false);
  };

  return (
    <>
      {content}

      {isSeo ? (
        <div className="relative w-full bg-white dark:bg-gray-900 mt-4 px-2">
          <div className="flex  items-center justify-between mb-4 pl-3 cursor-pointer">
            <div className="flex items-center gap-2">
              <span className="text-[#001C44] text-sm font-semibold dark:text-white">
                Imported Links
              </span>
              <input
                type="checkbox"
                className="cursor-pointer"
                onChange={() => handleCheckboxChange('links')}
                checked={reportForm?.optional_fields?.includes('links')}
              />
              <label className="dark:text-white text-[12px]">
                Not required
              </label>
            </div>
            {hideIcons?.includes('links') ? null : (
              <>
                {activeItems?.includes('links') ? (
                  <button onClick={() => handleSetActiveItems('links')}>
                    <AiOutlineMinus />
                  </button>
                ) : (
                  <button onClick={() => handleSetActiveItems('links')}>
                    <AiOutlinePlus />
                  </button>
                )}
              </>
            )}
            {/* {activeLinks ? (
              <button onClick={() => setActiveLinks(!activeLinks)}>
                <AiOutlineMinus />{' '}
              </button>
            ) : (
              <button onClick={() => setActiveLinks(!activeLinks)}>
                <AiOutlinePlus />{' '}
              </button>
            )} */}
          </div>
          {activeItems?.includes('links') && !hideIcons?.includes('links') ? (
            <>
              {links.length === 0 ? (
                <div className="mb-6">
                  <div className="mt-[20px] flex gap-4">
                    {' '}
                    <span className="dark:text-white ml-[20px] text-[14px]">
                      No Links Imported
                    </span>
                    <div
                      className={
                        !addReportLink
                          ? 'text-sm text-[#005C9F] font-[600] flex gap-2 items-center cursor-pointer w-fit'
                          : 'hidden'
                      }
                      onClick={() => {
                        setAddReportLink(true);
                      }}
                    >
                      Add <Icon name="Plus" color="#005C9F" />
                    </div>
                  </div>
                </div>
              ) : null}

              {links.length > 0 || citations.length > 0 ? (
                <>
                  {' '}
                  <span className="text-[#4a4848] dark:text-white text-sm">
                    Here is a collection of the links we obtained for you in
                    this cycle. Furthermore, we have created an outreach report
                    (see link below the table) that showcases the significant
                    efforts we undertook to reach out to different websites over
                    the last 30 days, all aimed at securing these links. If you
                    are unable to view the report, please contact your Account
                    Manager to arrange access.
                  </span>
                </>
              ) : null}
              {links.length ? (
                <table className="w-full text-sm text-left mt-5 border border-collapse rounded-lg">
                  <thead>
                    <tr className="w-full text-sm text-gray-600  bold  px-6 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 ">
                      <th className="p-2">Anchor</th>
                      <th className="text-center">Domain Ranking</th>
                      <th className="text-center">Target</th>
                      <th className="text-center">Link</th>
                      <th className="w-[50px]"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {links?.map((item, index) => {
                      return (
                        <tr className="dark:text-white" key={index}>
                          <td className="p-2">{item.anchor}</td>
                          <td className="text-center">{item.domain_ranking}</td>
                          <td>
                            <a
                              className="flex justify-center items-center"
                              href={item.target}
                              target="_blank"
                            >
                              <Icon name="Link01" />
                            </a>
                          </td>
                          <td>
                            <a
                              className="flex justify-center items-center"
                              href={item.link}
                              target="_blank"
                            >
                              <Icon name="Link01" />
                            </a>
                          </td>
                          <td className="w-[50px]">
                            <button
                              onClick={() => {
                                setOpenDeleteLinkModal(true);
                                setLinkIndex(index);
                              }}
                              className="px-4 flex justify-end w-full"
                            >
                              <Icon name="Xclose" color="#005C9F" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : null}
              {!addReportLink ? (
                <div
                  className={
                    links.length === 0
                      ? 'hidden'
                      : 'text-sm text-[#005C9F] font-[600] flex gap-2 items-center mt-4 cursor-pointer w-fit'
                  }
                  onClick={() => {
                    setAddReportLink((prevState) => !prevState);
                  }}
                >
                  Add more <Icon name="Plus" color="#005C9F" />
                </div>
              ) : (
                <div
                  className="text-sm text-[#005C9F] font-[600] flex gap-2 items-center mt-4 cursor-pointer w-fit"
                  onClick={() => {
                    setAddReportLink((prevState) => !prevState);
                  }}
                >
                  Close <Icon name="Xclose" color="#005C9F" />
                </div>
              )}
              {addReportLink === true ? (
                <form onSubmit={handleNewLinkSubmit}>
                  <div className="grid gap-6 mb-6 md:grid-cols-2 mt-4">
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Anchor
                      </label>
                      <input
                        type="text"
                        name="anchor"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={newLink.anchor}
                        onChange={handleNewLinkInputChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Domain Ranking
                      </label>
                      <input
                        type="number"
                        name="domain_ranking"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={newLink.domain_ranking}
                        onChange={handleNewLinkInputChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Target
                      </label>
                      <input
                        type="text"
                        name="target"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={newLink.target}
                        onChange={handleNewLinkInputChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Link
                      </label>
                      <input
                        type="text"
                        name="link"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={newLink.link}
                        onChange={handleNewLinkInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="w-full flex justify-end">
                    <button
                      type="submit"
                      className="text-white bg-[#005C9F] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Save
                    </button>
                  </div>
                </form>
              ) : null}
              <div className="text-[#001C44] text-sm font-semibold dark:text-white pl-3 mt-2">
                Citations
              </div>
              {citations.length ? (
                <table className="w-full text-sm text-left   border border-collapse rounded-lg mt-4">
                  <thead>
                    <tr className="w-full text-sm text-gray-600  bold  px-6 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 ">
                      <th className="p-2">Link</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {citations?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div
                              style={{ padding: 10 }}
                              className="link-text dark:text-white"
                            >
                              <a href={item} target="_blank">
                                {item}
                              </a>
                            </div>
                          </td>
                          <td className="w-[50px]">
                            <button
                              className="px-4 flex justify-end w-full"
                              onClick={() => {
                                setOpenDeleteCitationModal(true);
                                setCitationIndex(index);
                              }}
                            >
                              <Icon name="Xclose" color="#005C9F" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : null}
              {!addReportCitations ? (
                <div
                  className={
                    citations.length === 0
                      ? 'hidden'
                      : 'text-sm text-[#005C9F] font-[600] flex gap-2 items-center mt-4 cursor-pointer w-fit'
                  }
                  onClick={() => {
                    setAddReportCitations((prevState) => !prevState);
                  }}
                >
                  Add more <Icon name="Plus" color="#005C9F" />
                </div>
              ) : (
                <div
                  className="text-sm text-[#005C9F] font-[600] flex gap-2 items-center mt-4 cursor-pointer w-fit"
                  onClick={() => {
                    setAddReportCitations((prevState) => !prevState);
                  }}
                >
                  Close <Icon name="Xclose" color="#005C9F" />
                </div>
              )}
              {addReportCitations === true ? (
                <form onSubmit={handleNewCitationSubmit}>
                  <div className="grid gap-6 mb-6 md:grid-cols-2 mt-4">
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Link
                      </label>
                      <input
                        type="text"
                        name="citation"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={newCitation}
                        onChange={handleNewCitationInputChange}
                        required
                      />
                    </div>
                    <div className="w-full flex flex-col justify-end items-end">
                      <button
                        type="submit"
                        className="text-white bg-[#005C9F] w-fit hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}

              {citations.length === 0 ? (
                <div>
                  <div className="mt-[20px] flex gap-4">
                    {' '}
                    <span className="dark:text-white ml-[20px] text-[14px]">
                      No Citations Imported
                    </span>
                    <div
                      className={
                        !addReportCitations
                          ? 'text-sm text-[#005C9F] font-[600] flex gap-2 items-center cursor-pointer w-fit'
                          : 'hidden'
                      }
                      onClick={() => {
                        setAddReportCitations(true);
                      }}
                    >
                      Add <Icon name="Plus" color="#005C9F" />
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      ) : null}
      <CustomModal
        open={openDeleteLinkModal}
        onClose={() => setOpenDeleteLinkModal(false)}
      >
        <ConfirmationModal
          onClose={() => setOpenDeleteLinkModal(false)}
          onDelete={handleDeleteLink}
          linkIndex={linkIndex}
        />
      </CustomModal>
      <CustomModal
        open={openDeleteCitationModal}
        onClose={() => setOpenDeleteCitationModal(false)}
      >
        <ConfirmationModal
          onClose={() => setOpenDeleteCitationModal(false)}
          onDelete={handleDeleteCitation}
          linkIndex={citationIndex}
        />
      </CustomModal>
    </>
  );
};

export default Content;
