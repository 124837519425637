// WeeklyReport.tsx
import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { FaRedo } from 'react-icons/fa';
import CustomModal from '../../components/CustomModal';
import WeeklyReportingForm from '../../components/Modals/WeeklyReport';
import FlagModal from '../../components/Modals/WeeklyReport/Flag';
import ApproveModal from '../../components/Modals/WeeklyReport/Approve';
import SendModal from '../../components/Modals/WeeklyReport/Send';
import CancelModal from '../../components/Modals/WeeklyReport/Cancel';
import useWeeklyReportStore from '../../store/useWeeklyStore';
import TanStackTable from '../../components/TanStackTable';
import ReactSelect from '../../components/ReactSelect';
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';
import useVisibilityStore from '../../store/useVisibilityStore';
import useClientStore from '../../store/useClientStore';
import ImageIcon from '../../components/ImageIcon';
import Tooltip from '../../components/HelpTooltip';
import SearchBar from '../../components/Search';
import { formatToCurrency, formatNumber } from '../../utils/common';
import { FadeLoader } from 'react-spinners';
import ExportButton from '../../components/ExportCSV';
import ExportModal from '../../components/Modals/WeeklyReport/Export';
import { generatePPCColumns } from './ppcColumns';
import { generateSEOColumns } from './seoColumns';

function generateDateOptions(
  value?: string
): { value: string; label: string }[] {
  const startDate = moment('2023-10-19'); // Fixed start date
  const currentDate = moment(); // Test date set as Nov 10, 2023. You can replace it with moment() for actual current date.
  const options: { value: string; label: string }[] = [];

  let iteratingDate = startDate.clone();

  while (iteratingDate.isSameOrBefore(currentDate, 'day')) {
    const endOfWeek = iteratingDate.clone().endOf('isoWeek');

    if (endOfWeek.isSameOrBefore(currentDate, 'day')) {
      const startOfWeek = iteratingDate.clone().startOf('isoWeek');

      const monthName1 = startOfWeek.format('MMM');
      const monthName2 = endOfWeek.format('MMM');
      const yearName = endOfWeek.format('YYYY');

      const monthName1v = startOfWeek.format('MM');
      const monthName2v = endOfWeek.format('MM');

      const startDay = startOfWeek.format('DD');
      const endDay = endOfWeek.format('DD');

      const value = `${monthName1v}-${startDay}/${monthName2v}-${endDay}/${yearName}`;
      const label = `${monthName1}-${startDay} - ${monthName2} ${endDay}, ${yearName}`;

      // Check for duplicate entries and only push unique ones
      if (!options.some((option) => option.value === value)) {
        options.push({ value, label });
      }

      iteratingDate.add(7, 'days');
    } else {
      // Exit the loop if the end of the current period is in the future
      break;
    }
  }

  return options.reverse();
}

const dateOptions = generateDateOptions();
const options = dateOptions;

const statusOptions = [
  { value: 'all', label: 'All Status' },
  { value: 'ready', label: 'Ready' },
  { value: 'flagged', label: 'Flagged' },
  { value: 'sent', label: 'Sent' },
  { value: 'canceled', label: 'Canceled' },
];

const serviceTypeOptions = [
  { value: 'ppc_google', label: 'Google Ads' },
  { value: 'seo_google', label: 'Google SEO' },
];

// const errorOptions = [
//   { value: 'all', label: 'All Errors' },
//   { value: 'missing_integration', label: 'Missing Intergration' },
//   { value: '30_off', label: '30% off ' },
//   { value: 'no_budget', label: 'No Budget' },
// ];

const WeeklyReport: React.FC = () => {
  // Calculate the period number within the current month
  const { selectedClient } = useClientStore((state) => state);
  const isLeadGen = selectedClient?.category_type === 'Lead Gen';

  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [page, setPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState([]);
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const { setClient } = useClientStore((state: any) => state);
  const [cancelModal, setCancelModal] = useState(false);

  const defaultSelectedWeek = options[0];

  const [selectedWeek, setSelectedWeek] = useState<{
    value: string;
    label: string;
  } | null>(defaultSelectedWeek);
  const [selectedStatus, setSelectedStatus] = useState<{
    value: string;
    label: string;
  } | null>({ value: '', label: 'All Status' });

  const [showModal, setShowModal] = useState(false);
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [sendModal, setSendModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [rowState, setRowState] = useState(true);
  const [mount, setMount] = useState(false);
  const {
    fetchReports,
    reports,
    generateReport,
    fetchReportSummary,
    fetchErrors,
    errorOptions,
  } = useWeeklyReportStore();
  const isFlagged = selectedStatus?.value === 'flagged';
  const [, setItem] = useState({});
  const { openVisibility, setOpenVisibility } = useVisibilityStore();
  const [sortingDirection, setSortingDirection] = useState('');
  const [sortingColumn, setSortingColumn] = useState('');
  const [type, setType] = useState<{
    value: string;
    label: string;
  } | null>({ value: 'ppc_google', label: 'Google Ads' });
  const location = useLocation();

  let errorObjects = Object.entries(errorOptions).map(([key, value]) => ({
    value: value,
    label: value,
  }));

  errorObjects.unshift({ value: 'all', label: 'All Reports' });

  const [selectedError, setSelectedError] = useState<{
    value: string;
    label: string;
  } | null>({ value: 'all', label: 'All Reports' });

  const handleVisibilityClick = () => {
    setOpenVisibility(!openVisibility);
  };

  const handleErrorOptionsFetch = async () => {
    const payload = {
      refreshCache: true,
      report_basis: 'weekly',
    };

    await fetchErrors(payload);
  };

  const handleReportsFetch = (clear?: boolean) => {
    const [period] = (selectedWeek?.value ?? '').split(':');
    if (selectedError?.value === 'all') {
      const payload = {
        period: period,
        type: type.value,
        status: selectedStatus?.value,
        search: clear ? '' : searchText,
        page: page,
        sortBy: sortingColumn,
        sortOrder: sortingDirection,
      };
      fetchReports(payload);
    } else {
      const payload = {
        period: period,
        type: type.value,
        status: selectedStatus?.value,
        search: clear ? '' : searchText,
        page: page,
        sortBy: sortingColumn,
        sortOrder: sortingDirection,
        by_error: selectedError?.value,
      };
      fetchReports(payload);
    }

    setRowState(false);
  };

  useEffect(() => {
    const [period] = (selectedWeek?.value ?? '').split(':');
    if (mount) {
      handleReportsFetch();
      handleErrorOptionsFetch();
    }
    const summaryFilters = {
      date: period,
      report_basis: 'weekly',
      type: type.value,
      all_users: true,
    };
    fetchReportSummary(summaryFilters);
  }, [
    selectedWeek?.value,
    selectedStatus?.value,
    type?.value,
    page,
    sortingColumn,
    sortingDirection,
    fetchReportSummary,
    mount,
    selectedError?.value,
  ]);

  useEffect(() => {
    if (searchText === '' && mount) {
      handleReportsFetch();
    }
  }, [searchText]);

  useEffect(() => {
    // Get the status from the URL
    const queryParams = new URLSearchParams(location.search);
    const statusFromUrl = queryParams.get('status');
    const dateFromUrl = queryParams.get('date');
    const sortDirectionFromURL = queryParams.get('sortDirection');
    const sortColumnFromURL = queryParams.get('sortColumn');
    const errorFromUrl = queryParams.get('error');

    // Find the corresponding status option
    const statusOption = statusOptions.find(
      (option) => option.value === statusFromUrl
    );
    const dateOption = options.find((option) => option.value === dateFromUrl);
    if (Array.isArray(errorOptions)) {
      const errorOption = errorOptions.find(
        (option) => option.value === errorFromUrl
      );
      setSelectedError(errorOption);
      // Further processing using errorOption
    } else {
      console.error('errorOptions is not an array.');
    }

    // Update the state if the status is found, otherwise default to 'ready'
    setMount(true);
    setSelectedStatus(statusOption);
    setSortingColumn(sortColumnFromURL);
    setSortingDirection(sortDirectionFromURL);
    setSelectedWeek(dateFromUrl !== null ? dateOption : options[0]);
  }, [location]);

  const handleSortTable = (sortColumn, sortDirection) => {
    let newSortDirection = '';

    if (sortingDirection === '') {
      if (sortDirection === 'desc') {
        newSortDirection = 'asc';
      } else {
        newSortDirection = 'desc';
      }
    } else if (sortingDirection === 'asc') {
      newSortDirection = 'desc';
    } else if (sortingDirection === 'desc') {
      newSortDirection = null;
    } else if (sortingDirection === null) {
      newSortDirection = 'asc';
    }

    if (sortingColumn !== sortColumn) {
      newSortDirection = 'asc';
    } else if (sortingDirection === 'asc') {
      newSortDirection = 'desc';
    } else if (sortingDirection === 'desc') {
      newSortDirection = null;
    } else if (sortingDirection === null) {
      newSortDirection = 'asc';
    }

    if (sortDirection === null) {
      setSortingColumn('');
    }

    setSortingDirection(newSortDirection);
    setSortingColumn(sortColumn);

    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('sortDirection', newSortDirection);
    newUrl.searchParams.set('sortColumn', sortColumn);
    window.history.pushState({}, '', newUrl);
  };

  const handleEdit = (report: any) => {
    setSelectedReport(report);
    setShowModal(true);
  };
  const handleSend = (report: any) => {
    setSelectedReport(report);
    setSendModal(true);
    setSelectedRow(report);
  };
  const handleFlag = (report: any) => {
    setSelectedReport(report);
    setShowFlagModal(true);
    setSelectedRow(report);
  };
  const handleApprove = (report: any) => {
    setSelectedReport(report);
    setApproveModal(true);
    setSelectedRow(report);
  };

  const handleCancel = (report: any) => {
    setSelectedReport(report);
    setCancelModal(true);
    setSelectedRow(report);
  };

  const handleRegenerate = (report: any) => {
    const data = {
      reports: [report.id],
    };
    setIsGenerating(true);
    generateReport(data)
      .then(() => {
        handleReportsFetch();
        setIsGenerating(false);
      })
      .catch((err: any) => {
        setIsGenerating(false);
        console.log(err);
      });
  };

  const formattedDataMemo = useMemo(() => {
    const formatData = (data: any) => {
      if (!data || !Array.isArray(data)) return null;
      if (type.value === 'ppc_google') {
        const reportsData = data.map((item: any) => {
          return {
            client: item,
            budget: formatToCurrency(item?.report?.monthly_budget || 0),
            spend: formatToCurrency(item?.report?.seven_days?.cost_micros || 0),
            budgetSpend: formatToCurrency(item?.report.spend_to_date),
            budgetToDate: formatToCurrency(item?.report.budget_to_date),
            impression: formatNumber(
              item?.report?.seven_days?.impressions || 0
            ),
            clicks: formatNumber(item?.report?.seven_days?.clicks || 0),
            conversions: formatNumber(
              item?.report?.seven_days?.conversions || 0
            ),
            budgetDiff: item?.report?.budget_difference || 0,
            status: item.status,
            actions: item,
            CTR: formatNumber(item?.report?.seven_days?.ctr, true) || 0,
            averageCpc:
              formatToCurrency(item?.report?.seven_days?.average_cpc) || 0,
            CPC:
              formatToCurrency(item?.report?.seven_days?.cost_per_conversion) ||
              0,
            conversionRate:
              formatNumber(item?.report?.seven_days?.conversion_rate, true) ||
              0,
            conversionsValue:
              formatToCurrency(item?.report?.seven_days?.conversions_value) ||
              0,
            ReturnOnAdSpend:
              formatToCurrency(item?.report?.seven_days?.roas) || 0,
          };
        });
        return reportsData;
      } else {
        const reportsData = data.map((item: any) => {
          return {
            client: item,
            clicks: formatNumber(item?.report?.seven_days?.clicks || 0),
            impression: formatNumber(
              item?.report?.seven_days?.impressions || 0
            ),
            CTR: formatNumber(item?.report?.seven_days?.ctr, true) || 0,
            newUsers: formatNumber(item?.report?.seven_days?.newUsers) || 0,
            organic_newUsers:
              formatNumber(item?.report?.seven_days?.organic_newUsers) || 0,
            organic_sessions:
              formatNumber(item?.report?.seven_days?.organic_sessions) || 0,
            organic_engagedSessions:
              formatNumber(item?.report?.seven_days?.organic_engagedSessions) ||
              0,
            organic_conversions:
              formatNumber(item?.report?.seven_days?.organic_conversions) || 0,
            organic_conversions_rate:
              formatNumber(
                item?.report?.seven_days?.organic_conversions_rate,
                true
              ) || 0,
            top_pages_10: 0,
            top_page: 0,
            top_3: 0,
            refDomain: item?.report?.seven_days?.refDomain,
            domain_rating:
              formatNumber(item?.report?.seven_days?.domain_rating) || 0,
            url_rating: item?.report?.seven_days?.url_rating,
            actions: item,
          };
        });
        return reportsData;
      }
    };
    const formattedData = formatData(reports.data);
    return formattedData;
  }, [reports.data]);

  const navToClientDetails = ({ client }: any) => {
    setClient(client);
  };
  const handleSearch = () => {
    try {
      handleReportsFetch();
      setPage(page);
      setRowState(false);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = useMemo(() => {
    if (type.value === 'ppc_google') {
      return generatePPCColumns({
        sortingColumn,
        sortingDirection,
        navToClientDetails,
        isFlagged,
        handleRegenerate,
        handleEdit,
        handleSend,
        handleApprove,
        handleFlag,
        setItem,
      });
    } else {
      return generateSEOColumns({
        sortingColumn,
        sortingDirection,
        navToClientDetails,
        isFlagged,
        handleRegenerate,
        handleEdit,
        handleSend,
        handleApprove,
        handleFlag,
        setItem,
        isLeadGen,
      });
    }
  }, [
    sortingColumn,
    sortingDirection,
    isFlagged,
    handleRegenerate,
    handleEdit,
    handleSend,
    handleApprove,
    handleFlag,
    setItem,
    type.value,
  ]);

  const handleFiltersChange = (selectedOption: any, actionMeta: any) => {
    const newUrl = new URL(window.location.href);
    if (actionMeta === 'date') {
      setSelectedWeek(selectedOption);
      newUrl.searchParams.set('date', selectedOption.value);
    }
    if (actionMeta === 'error') {
      setSelectedError(selectedOption);
      newUrl.searchParams.set('error', selectedOption.value);
    } else if (actionMeta === 'status') {
      setSelectedStatus(selectedOption);
      newUrl.searchParams.set('status', selectedOption.value);
    }
    if (actionMeta === 'type') {
      setType(selectedOption);
      newUrl.searchParams.set('report_type', selectedOption.value);
    }
    window.history.pushState({}, '', newUrl);
  };

  return (
    <div className="bg-[#D2EEFF] mt-12 px-2 sm:mt-0 sm:py-3 sm:px-[32px] overflow-hidden min-h-[93dvh]   rounded-md dark:bg-[#191919] font-inter scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8 rounded-lg">
      <div className="bg-[#D2EEFF] pt-2 sm:pt-0 rounded-lg dark:bg-[#0C111D]">
        <div className="flex items-center justify-between mt-4 px-2 py-4 sm:mt-0 sm:pt-14 lg:p-4 bg-white dark:bg-gray-800 lg:sticky top-0 z-10 rounded-t-lg">
          <div className="flex flex-wrap items-center gap-2 sm:gap-6 relative">
            <SearchBar
              value={searchText}
              onChange={setSearchText}
              onSearch={handleSearch}
              placeholder="Search for report"
            />

            <div>
              <ReactSelect
                options={options}
                handleOnChange={(value: any) =>
                  handleFiltersChange(value, 'date')
                }
                value={selectedWeek}
                placeholder="Select Week"
              />
            </div>
            <div className="max-w-[300px]">
              <ReactSelect
                options={errorObjects ? errorObjects : []}
                handleOnChange={(value: any) =>
                  handleFiltersChange(value, 'error')
                }
                value={selectedError}
                placeholder="All Reports"
              />
            </div>
            <div className="max-w-[300px]">
              <ReactSelect
                options={serviceTypeOptions}
                handleOnChange={(value: any) =>
                  handleFiltersChange(value, 'type')
                }
                value={type}
                placeholder="Google Ads"
              />
            </div>
            {/* <div>
              <ReactSelect
                options={statusOptions}
                handleOnChange={(value: any) =>
                  handleFiltersChange(value, 'status')
                }
                value={selectedStatus}
                placeholder="Select Status"
              />
            </div> */}

            <button
              className="block sm:hidden p-2 rounded-md border"
              onClick={() => setShowExportModal(true)}
            >
              <Icon
                name="Download"
                size={16}
                color={mode === 'dark' ? '#fff' : iconColor}
              />
            </button>
            <button
              className="flex sm:hidden items-center py-2 px-3.5 gap-2 rounded-lg border border-[#808EA2]"
              onClick={handleVisibilityClick}
            >
              <span className="text-[#001C44] text-sm font-semibold dark:text-white">
                Columns
              </span>
              <Icon
                name="offEye"
                color={mode === 'dark' ? '#fff' : iconColor}
              />
            </button>
            {selectedRow.length !== 0 && selectedStatus?.value === 'ready' && (
              <div className="flex sm:hidden gap-2 justify-center items-center">
                <div>
                  <button
                    type="button"
                    onClick={() => handleSend(selectedRow)}
                    className="rounded-8 text-sm flex items-center gap-2 bg-[#005c9f] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white font-inter"
                  >
                    Send
                    <Icon name="Submit" />
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => handleFlag(selectedRow)}
                    className="rounded-8 text-sm flex items-center gap-2 bg-[#d92d20] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white font-inter"
                  >
                    Flag
                    <Icon name="Cancel" />
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => handleCancel(selectedRow)}
                    className="rounded-8 text-sm flex items-center gap-2 bg-[#d92d20] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white font-inter"
                  >
                    Cancel
                    <Icon name="Cancel" />
                  </button>
                </div>
              </div>
            )}
            {selectedRow.length !== 0 &&
              selectedStatus?.value === 'flagged' && (
                <div className="flex sm:hidden gap-2 justify-center items-center">
                  <div>
                    <button
                      type="button"
                      onClick={() => handleApprove(selectedRow)}
                      className="rounded-8 text-sm flex items-center gap-2 bg-[#005c9f] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white font-inter"
                    >
                      Approve
                      <Icon name="Submit" />
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => handleCancel(selectedRow)}
                      className="rounded-8 text-sm flex items-center gap-2 bg-[#d92d20] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white font-inter"
                    >
                      Cancel
                      <Icon name="Cancel" />
                    </button>
                  </div>
                </div>
              )}
            {selectedRow.length !== 0 && selectedStatus?.value === 'sent' && (
              <div className="flex sm:hidden gap-2 justify-center items-center">
                <div>
                  <button
                    type="button"
                    onClick={() => handleSend(selectedRow)}
                    className="rounded-8 text-sm flex items-center gap-2 bg-[#005c9f] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white font-inter"
                  >
                    Resend
                    <Icon name="Submit" />
                  </button>
                </div>
              </div>
            )}
          </div>
          {isGenerating && (
            <FadeLoader
              style={{
                position: 'absolute',
                top: '30%',
                left: '45%',
                zIndex: 3,
              }}
              color={'blue'}
            />
          )}
          <div className="hidden sm:flex items-center gap-2">
            {selectedRow.length !== 0 && selectedStatus?.value === 'ready' && (
              <div className="flex gap-2 justify-center items-center">
                <div>
                  <button
                    type="button"
                    onClick={() => handleSend(selectedRow)}
                    className="rounded-8 text-sm flex items-center gap-2 bg-[#005c9f] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white font-inter"
                  >
                    Send
                    <Icon name="Submit" />
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => handleFlag(selectedRow)}
                    className="rounded-8 text-sm flex items-center gap-2 bg-[#d92d20] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white font-inter"
                  >
                    Flag
                    <Icon name="Cancel" />
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => handleCancel(selectedRow)}
                    className="rounded-8 text-sm flex items-center gap-2 bg-[#d92d20] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white font-inter"
                  >
                    Cancel
                    <Icon name="Cancel" />
                  </button>
                </div>
              </div>
            )}
            {selectedRow.length !== 0 &&
              selectedStatus?.value === 'flagged' && (
                <div className="flex gap-2 justify-center items-center">
                  <div>
                    <button
                      type="button"
                      onClick={() => handleApprove(selectedRow)}
                      className="rounded-8 text-sm flex items-center gap-2 bg-[#005c9f] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white font-inter"
                    >
                      Approve
                      <Icon name="Submit" />
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => handleCancel(selectedRow)}
                      className="rounded-8 text-sm flex items-center gap-2 bg-[#d92d20] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white font-inter"
                    >
                      Cancel
                      <Icon name="Cancel" />
                    </button>
                  </div>
                </div>
              )}
            {selectedRow.length !== 0 && selectedStatus?.value === 'sent' && (
              <div className="flex gap-2 justify-center items-center">
                <div>
                  <button
                    type="button"
                    onClick={() => handleSend(selectedRow)}
                    className="rounded-8 text-sm flex items-center gap-2 bg-[#005c9f] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white font-inter"
                  >
                    Resend
                    <Icon name="Submit" />
                  </button>
                </div>
              </div>
            )}
            <div className="flex gap-x-8 items-center">
              <button
                className="p-2 rounded-md border"
                onClick={() => setShowExportModal(true)}
              >
                <Icon
                  name="Download"
                  size={16}
                  color={mode === 'dark' ? '#fff' : iconColor}
                />
              </button>
              <button
                className="flex items-center py-2 px-3.5 gap-2 rounded-lg border border-[#808EA2]"
                onClick={handleVisibilityClick}
              >
                <span className="text-[#001C44] text-sm font-semibold dark:text-white">
                  Columns
                </span>
                <Icon
                  name="offEye"
                  color={mode === 'dark' ? '#fff' : iconColor}
                />
              </button>
            </div>
          </div>
        </div>
        <TanStackTable
          data={!formattedDataMemo ? [] : formattedDataMemo}
          columns={columns}
          openVisibility={openVisibility}
          setOpenVisibility={setOpenVisibility}
          totalPages={!reports.total_pages ? 0 : reports.total_pages}
          page={page}
          setPage={setPage}
          loading={reports?.loading}
          setSelectedRow={setSelectedRow}
          rowState={rowState}
          setRowState={setRowState}
          totalDocs={reports?.total_docs}
          sortData={handleSortTable}
          stickyColumn="name"
        />
        <CustomModal open={showModal} onClose={() => setShowModal(false)}>
          <WeeklyReportingForm
            selectedReport={selectedReport}
            fetchReports={handleReportsFetch}
            selectedWeek={selectedWeek}
            report_type={type.value}
            onClose={() => setShowModal(false)}
          />
        </CustomModal>
        <CustomModal
          open={showFlagModal}
          onClose={() => {
            setRowState(false);
            setShowFlagModal(false);
          }}
        >
          <FlagModal
            closeModal={() => {
              setRowState(false);
              setShowFlagModal(false);
            }}
            selectedReport={selectedReport}
            fetchReports={handleReportsFetch}
            setRowState={() => setRowState(false)}
          />
        </CustomModal>
        <CustomModal
          open={approveModal}
          onClose={() => {
            setRowState(false);
            setApproveModal(false);
          }}
        >
          <ApproveModal
            closeModal={() => {
              setRowState(false);
              setApproveModal(false);
            }}
            selectedReport={selectedReport}
            fetchReports={handleReportsFetch}
          />
        </CustomModal>
        <CustomModal
          open={cancelModal}
          onClose={() => {
            setRowState(false);
            setCancelModal(false);
          }}
        >
          <CancelModal
            closeModal={() => {
              setRowState(false);
              setCancelModal(false);
            }}
            selectedReport={selectedReport}
            fetchReports={handleReportsFetch}
            setRowState={() => setRowState(false)}
          />
        </CustomModal>
        <CustomModal
          open={sendModal}
          onClose={() => {
            setRowState(false);
            setSendModal(false);
          }}
        >
          <SendModal
            closeModal={() => {
              setRowState(false);
              setSendModal(false);
            }}
            selectedReport={selectedReport}
            fetchReports={handleReportsFetch}
          />
        </CustomModal>
        <CustomModal
          open={showExportModal}
          onClose={() => {
            setRowState(false);
            setShowExportModal(false);
          }}
        >
          <ExportModal
            closeModal={() => {
              setRowState(false);
              setShowExportModal(false);
            }}
            selectDate={selectedWeek}
          />
        </CustomModal>
      </div>
    </div>
  );
};

export default WeeklyReport;
